import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from '@mui/material'
import { omit } from 'lodash'
import React, { ReactNode, SyntheticEvent } from 'react'

export type ConfirmationDialogProps = Omit<DialogProps, 'title'> & {
  title?: ReactNode
  cancelLabel?: string
  confirmLabel?: string
  message: ReactNode
  onConfirm?: () => void
  onCancel?: () => void
  onClose: (event?: SyntheticEvent<unknown, Event>) => void
}

export default function ConfirmationDialog(
  props: ConfirmationDialogProps,
) {
  const { cancelLabel, confirmLabel } = props
  return (
    <Dialog
      {...omit(props, [
        'onConfirm',
        'onCancel',
        'message',
        'cancelLabel',
        'confirmLabel',
        'title',
      ])}
      maxWidth="xs"
      fullWidth
    >
      {props.title && (
        <DialogTitle sx={{ mt: 0.5 }}>{props.title}</DialogTitle>
      )}
      <DialogContent>{props.message}</DialogContent>
      <DialogActions>
        {cancelLabel && (
          <Button
            color="secondary"
            autoFocus
            onClick={() => {
              props.onCancel?.()
              props.onClose()
            }}
          >
            {cancelLabel}
          </Button>
        )}
        {confirmLabel && (
          <Button
            variant="text"
            onClick={() => {
              props.onConfirm?.()
              props.onClose()
            }}
          >
            {confirmLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
