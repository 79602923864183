import { useAuth } from '@frontegg/react'
import { Box, PaletteMode } from '@mui/material'
import AppSnackbar from 'components/AppSnackbar'
import LoadingBackdrop from 'components/LoadingBackdrop'
import MainNavBar from 'components/MainNavBar'
import { PageContext } from 'components/PageContext'
import useAutoLogout from 'hooks/useAutoLogout'
import useRouteCheck from 'hooks/useRouteCheck'
import ForbiddenPage from 'pages/forbidden'
import React, {
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Environment, SelectOption } from 'types/Global'
import UserUtil from 'utils/UserUtil'
import {
  useAreaOfConcernsQuery,
  useLabsQuery,
  useLoggedInUserLazyQuery,
} from '__generated__/graphql'
import SessionExpiredDialog from './AutoLogout/SessionExpiredDialog'
import SessionInactiveDialog from './AutoLogout/SessionInactiveDialog'
import PendoScript from './PendoScript'
import { TrackerContext } from './TrackerContext'
import { sanitize } from 'isomorphic-dompurify'
import * as Sentry from '@sentry/nextjs'
import { useLocalStorage } from 'react-use'
import { LocalStorageKey } from 'hooks/useLogout'
import useAuthTenants from 'hooks/useAuthTenants'
import { SelectAccountDialog } from './SelectAccountDialog'
import useDialog from 'hooks/useDialog'
import { AccountSettings } from '@shared/constants'

const ACTIVITY_EXPIRATION =
  process.env.NODE_ENV === Environment.DEVELOPMENT
    ? 3600000 * 12 // 12 hours
    : 60000 * 30 // 30 min

type AppBaseProps = {
  themeMode: PaletteMode
  setThemeMode: (mode: PaletteMode) => void
  children: ReactNode
}

export default function AppBase(props: AppBaseProps) {
  const { themeMode, setThemeMode } = props
  const { user: fronteggUser, isAuthenticated } = useAuth()
  const { isMultiTenant } = useAuthTenants()
  const { initTracker } = useContext(TrackerContext)
  const [fetchLoggedInUser, { data: loggedInUserData }] =
    useLoggedInUserLazyQuery()
  const [displayAccountSelectionDialogOnLogin] = useLocalStorage(
    LocalStorageKey.DISPLAY_ACCOUNT_SELECTION_DIALOG_ON_LOGIN,
    true,
  )
  const [
    selectAccountDialogProps,
    { setOpen: setSelectAccountDialogOpen },
  ] = useDialog()
  const loggedInUser = loggedInUserData?.portalApp?.loggedInUser
  const settings = loggedInUser?.account?.settings as AccountSettings
  const ehrSettings = settings?.ehr
  const isInIframe = window.location !== window.parent.location
  const [loadingBackdropOpen, setLoadingBackdropOpen] =
    useState(false)
  const { data: labsData } = useLabsQuery({
    skip: !isAuthenticated,
  })
  const labOptions: SelectOption[] = (labsData?.labs ?? []).map(
    (lab) => ({
      label: lab.name,
      value: lab.id,
    }),
  )
  const { data: areaOfConcernsData } = useAreaOfConcernsQuery({
    skip: !isAuthenticated,
  })
  const areaOfConcernOptions =
    areaOfConcernsData?.areaOfConcerns ?? []
  const { isAuthRequired, isDelegateAllowed, isUploadedContent } =
    useRouteCheck()
  const {
    logout,
    sessionIsExpired,
    sessionIsInactive,
    setSessionIsInactive,
  } = useAutoLogout(ACTIVITY_EXPIRATION, !isAuthRequired)

  const isSuperUser = !!fronteggUser?.superUser
  const hasPortalRoleAccess =
    UserUtil.hasPortalRoleAccess(fronteggUser)

  useEffect(() => {
    initTracker()
  }, [initTracker])

  useEffect(() => {
    if (isAuthenticated) {
      fetchLoggedInUser()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  // Set Sentry user and portalUser context on portal login.
  useEffect(() => {
    if (loggedInUser?.id) {
      Sentry.setUser({ id: loggedInUser?.id })
      Sentry.setContext('portalUser', {
        accountId: loggedInUser?.account?.id,
        fronteggId: loggedInUser?.fronteggId,
        userId: loggedInUser?.id,
      })
    }
  }, [loggedInUser])

  // Set Sentry user and portalUser context on portal login.
  useEffect(() => {
    if (isMultiTenant && displayAccountSelectionDialogOnLogin) {
      setSelectAccountDialogOpen(true)
    }
  }, [
    isMultiTenant,
    displayAccountSelectionDialogOnLogin,
    setSelectAccountDialogOpen,
  ])

  // Avoid app render until user is loaded and authenticated.
  if (isAuthRequired && (!isAuthenticated || !fronteggUser)) {
    return null
  }

  // Multi tenant dialog upon login
  if (
    !ehrSettings &&
    isMultiTenant &&
    displayAccountSelectionDialogOnLogin
  ) {
    return <SelectAccountDialog {...selectAccountDialogProps} />
  }

  // Display forbidden for users with insufficient roles.
  if (
    !isSuperUser &&
    isAuthRequired &&
    (!hasPortalRoleAccess ||
      (UserUtil.hasDelegateRole(fronteggUser) && !isDelegateAllowed))
  ) {
    if (isDelegateAllowed) {
      // Check for redirect before rendering forbidden page
      const redirectUrl = localStorage.getItem(
        LocalStorageKey.REDIRECT_AFTER_LOGIN,
      )
      if (redirectUrl) {
        localStorage.removeItem(LocalStorageKey.REDIRECT_AFTER_LOGIN)
        const sanitizedUrl = sanitize(redirectUrl, {
          ALLOWED_URI_REGEXP:
            /^(?:(?:(?:f|ht)tps?|mailto|tel|callto|cid|xmpp):|[^a-z]|[a-z+.\-]+(?:[^a-z+.\-:]|$))/i, // eslint-disable-line no-useless-escape
        })
        window.location.href = sanitizedUrl as string
      }
    }
    return <ForbiddenPage />
  }

  return (
    <PageContext.Provider
      value={{
        account: loggedInUser?.account,
        areaOfConcernOptions,
        fronteggUser,
        isInIframe,
        labOptions,
        loadingBackdropOpen,
        loggedInUser,
        setLoadingBackdropOpen,
        setThemeMode,
        themeMode,
      }}
    >
      {isAuthRequired && !isInIframe && !isUploadedContent && (
        <MainNavBar />
      )}
      <Box
        id="main"
        component="main"
        sx={[
          {
            display: 'flex',
            flexGrow: 1,
            mx: 'auto',
            position: 'relative',
            width: 1,
          },
        ]}
      >
        {props.children}
      </Box>
      <AppSnackbar />
      <SessionExpiredDialog
        onClose={logout}
        open={sessionIsExpired}
      />
      <SessionInactiveDialog
        onConfirm={logout}
        onClose={() => setSessionIsInactive(false)}
        open={sessionIsInactive}
      />
      <PendoScript />
      <LoadingBackdrop open={loadingBackdropOpen} />
    </PageContext.Provider>
  )
}
