export type {
  SelectOption,
  SelectNumberOption,
} from '@shared/rule-properties-util'

// Tel E.164 format `+16076467518`
export type Tel =
  `+1${number}${number}${number}${number}${number}${number}${number}${number}${number}${number}`
export type Email = `${string}@${string}.${string}`
// Date ISO format `1964-09-02`
export type Date =
  `${number}${number}${number}${number}-${number}${number}-${number}${number}`
// Time ISO format `20:21:50.431`
export type Time =
  `${number}${number}:${number}${number}:${number}${number}.${number}${number}${number}`
// DateTime ISO format `2021-03-29T20:17:32.289Z`
export type DateTime = `${Date}T${Time}Z`

export enum Environment {
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export enum GraphQLErrorCode {
  GRAPHQL_PARSE_FAILED = 'GRAPHQL_PARSE_FAILED',
  GRAPHQL_VALIDATION_FAILED = 'GRAPHQL_VALIDATION_FAILED',
  BAD_USER_INPUT = 'BAD_USER_INPUT',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  FORBIDDEN = 'FORBIDDEN',
  PERSISTED_QUERY_NOT_FOUND = 'PERSISTED_QUERY_NOT_FOUND',
  PERSISTED_QUERY_NOT_SUPPORTED = 'PERSISTED_QUERY_NOT_SUPPORTED',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  NOT_FOUND = 'NOT_FOUND',
}

export type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[]
    ? ElementType
    : never
