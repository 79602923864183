import { noop } from 'lodash'
import React from 'react'
import { PaletteMode } from '@mui/material'
import { SelectOption } from 'types/Global'
import { User } from '@frontegg/redux-store'
import { LoggedInUserQuery } from '__generated__/graphql'

export type LoggedInUser =
  LoggedInUserQuery['portalApp']['loggedInUser']

export const PageContext = React.createContext({
  account: null as LoggedInUser['account'],
  areaOfConcernOptions: [] as SelectOption[],
  fronteggUser: null as User,
  isInIframe: false,
  labOptions: [] as SelectOption[],
  loadingBackdropOpen: false,
  loggedInUser: null as LoggedInUser,
  setLoadingBackdropOpen: noop,
  setThemeMode: noop as (mode: PaletteMode) => void,
  themeMode: 'light' as PaletteMode,
})
