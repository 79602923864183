import { useCallback } from 'react'
import { useLoginActions } from '@frontegg/react' // Adjust the import based on your Frontegg setup

export enum LocalStorageKey {
  DISPLAY_ACCOUNT_SELECTION_DIALOG_ON_LOGIN = 'displayAccountSelectionDialogOnLogin',
  LAST_ACTIVITY = 'lastActivity',
  REDIRECT_AFTER_LOGIN = 'redirectAfterLogin',
  SCHEDULED_LOGOUT = 'scheduledLogout',
}

export function abandonScheduledLogout() {
  const wasLogoutScheduled = localStorage.getItem(
    LocalStorageKey.SCHEDULED_LOGOUT,
  )

  if (wasLogoutScheduled) {
    console.log('Abandoned scheduled logout')
  }

  clearLocalStorageKeys([
    LocalStorageKey.SCHEDULED_LOGOUT,
    LocalStorageKey.LAST_ACTIVITY,
  ])
}

export function abandonRedirectAfterLogout() {
  clearLocalStorageKeys([LocalStorageKey.REDIRECT_AFTER_LOGIN])
}

const clearLocalStorageKeys = (keys: LocalStorageKey[]) => {
  keys.forEach((key) => {
    localStorage.removeItem(key)
  })
}

const useLogout = () => {
  const { logout: fronteggLogout } = useLoginActions()

  const logout = useCallback(
    async ({
      logoutCallback,
    }: {
      logoutCallback?: () => void
    } = {}) => {
      // Clear the local storage keys
      clearLocalStorageKeys([
        LocalStorageKey.DISPLAY_ACCOUNT_SELECTION_DIALOG_ON_LOGIN,
      ])

      fronteggLogout(logoutCallback)
    },
    [fronteggLogout],
  )

  return { logout }
}

export default useLogout
