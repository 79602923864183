import {
  useAuth,
  useTenantsActions,
  useTenantsState,
} from '@frontegg/react'
import {
  useSuperUserTenantsListQuery,
  useTenantsListQuery,
} from '__generated__/graphql'
import { sortBy, uniqBy } from 'lodash'
import UserUtil, { checkNestStandardAccount } from 'utils/UserUtil'

export default function useAuthTenants() {
  const { isAuthenticated, user } = useAuth()
  const isSuperUser = !!user?.superUser
  const isDelegate = !isSuperUser && UserUtil.hasDelegateRole(user)
  const isAdmin = isSuperUser || UserUtil.hasAdminRole(user)
  const { switchTenant } = useTenantsActions()
  const { tenants: topLevelTenants } = useTenantsState()
  const isNestStandardUser = checkNestStandardAccount(user)

  const tenantsWithSubTenants = topLevelTenants.filter(
    (tenant) => tenant.isReseller,
  )
  const { data, loading } = useTenantsListQuery({
    skip:
      !tenantsWithSubTenants?.length ||
      isSuperUser ||
      !isAuthenticated,
    variables: {
      tenantIds: tenantsWithSubTenants.map(
        (tenant) => tenant.tenantId,
      ),
    },
  })

  const {
    data: superUserTenantsList,
    loading: superUserTenantsLoading,
  } = useSuperUserTenantsListQuery({
    skip: !isSuperUser || !isAuthenticated,
  })

  const tenants = isSuperUser
    ? (superUserTenantsList?.portalApp?.allTenants?.map((tenant) => ({
        id: tenant.tenantId,
        name: tenant.name,
      })) ?? [])
    : sortBy(
        uniqBy(
          [
            ...(topLevelTenants?.map((tenant) => ({
              id: tenant.tenantId,
              name: tenant.name,
            })) ?? []),
            ...(data?.portalApp?.subtenants ?? []).map((tenant) => ({
              id: tenant.tenantId,
              name: tenant.name,
            })),
          ],
          'id',
        ),
        'name',
      )

  const isMultiTenant = tenants.length > 1

  return {
    isAdmin,
    isDelegate,
    isMultiTenant,
    isNestStandardUser,
    isSuperUser,
    loading: loading || superUserTenantsLoading,
    switchTenant,
    tenants,
  }
}
